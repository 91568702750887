@media (max-width: 1080px) {
  .banner {
    background-size: cover;
  }
  .banner2 {
    background-position: 60% 50% !important;
  }
  .head-test {
    width: 100%;
  }
  .about-title {
    width: 100%;
    padding: 0px 30px;
  }
  .talk-content {
    width: 100%;
    padding: 0px 30px;
  }
  .talk-photo {
    width: 90%;
  }
  .clients-container {
    flex-direction: row;
  }
  .client-layers {
    flex-direction: column;
    height: 600px;
  }
  .blogs-title {
    height: 60px;
  }

  .blog-row {
    height: 250px;
  }

  .blog-card {
    height: 100%;
  }

  .blog-image {
    height: 100%;
  }

  .blog-title a h3 {
    font-size: 20px;
    margin-top: 0px;
  }
  .blog-card-det {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    align-items: start;
  }
  .blogs-wrapper iframe {
    position: fixed !important;
    width: 370px !important;
  }
}

@media (max-width: 840px) {
  .banner {
    background-position: 20% 50%;
  }
  .banner.banner2 {
    background-position: 70% 50% !important;
  }
  .talk-photo {
    width: 100%;
    height: 400px;
  }
  .banner p {
    font-size: 14px;
  }
  .blogs-wrapper iframe {
    position: fixed !important;
    width: 300px !important;
  }
}

@media (max-width: 767px) {
  .slick-dots {
    width: 50% !important;
    left: 25% !important;
  }
  .slick-next {
    right: 10% !important;
    top: 100% !important;
  }
  .slick-prev {
    left: 10% !important;
    top: 100% !important;
    z-index: 10;
  }
}


@media (max-width: 576px) {
  .flex-div {
    flex-direction: column;
  }
  .banner {
    background-position: 30% 50%;
  }
  .banner-content {
    & h4 {
      font-size: 26px;
    }
    & h1 {
      font-size: 42px;
    }
  }
  .book-btn {
    padding: 10px 16px;
  }
  .navbar-sparks-brand {
    height: 80px;
  }
  .talk-tabs {
    flex-direction: column;
  }
  .talk-tab a {
    margin-top: 8px;
    width: 66vw;
  }
  .jastrow {
    height: 246px;
  }
  .sparks-content {
    flex-direction: column;
  }
  .bio {
    padding: 0px 20px;
  }
  .blog {
    width: 100%;
    float: none;
  }
  .blogs-wrapper iframe {
    position: relative !important;
    width: 100% !important;
    min-height: 280px !important;
    height: 75vh !important;
  }
  .blogs-wrapper {
    padding-top: 93px;
  }
  .blogs-title {
    height: 70px;
  }

}


@media (max-width: 463px) {
  .banner {
    background-position: 40% 50%;
  }
  .jastrow {
    height: 175px;
  }
  .banner-content {
    & h4 {
      font-size: 18px;
    }
    & h1 {
      font-size: 30px;
    }
  }
  .book-btn-green {
    font-size: 14px;
  }
  .book-btn {
    font-size: 14px;
  }
  .head-test {
    display: none;
  }
  h2 {
    font-size: 28px;
  }
  p {
    font-size: 16px;
  }
  .talk-book-btn {
    margin-bottom: 0px;
  }
  .talk-photo {
    margin-bottom: 0px;
  }
  .client-image {
    padding: 5px;
  }
  .client-image img {
    width: 60px;
  }
  .magic-image {
    width: 100px;
    height: 100px;
  }
}

@media (max-width: 350px) {
  .jastrow {
    height: 150px;
  }
  .client-image img {
    width: 80px;
    padding: 5px;
  }
}

@media (max-height: 420px) {
  .arrow-container {
    display: none;
  }
  .banner {
    background-size: 150%;
    background-position: 20% 40%;
  }
  .banner-content {
    width: 80%;
    & h1 {
      font-size: 30px;
    }
    &  h4 {
      font-size: 18px;
    }
  }
  .book-btn-green {
    font-size: 14px;
    padding: 5px 20px;
    width: 150px;
  }
  .book-btn {
    font-size: 14px;
    padding: 5px 20px;
    width: 150px;
  }
  .navbar-sparks-brand {
    height: 50px;
  }
  .head-test {
    display: none;
  }
  h2 {
    font-size: 26px;
  }
  h3 {
    font-size: 23px;
  }
  h4 {
    font-size: 18px;
  }
  p {
    font-size: 15px;
  }
  .vid-resp {
    width: 57%;
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
  .talk-photo {
    width: 100%;
    height: 235px;
  }
  .blogs-wrapper {
    padding: 65px 35px;
  }

  .blog {
    width: 100%;
    float: none;
  }

  .blogs-wrapper iframe {
    position: relative !important;
    width: 100% !important;
    min-height: 280px !important;
    height: 77vh !important;
  }
}
