.video-wrapper {
  height: auto;
  background-image: linear-gradient(to right, $brand-primary2, #033065);
}

.video-title {
  margin-top: 50px;
  text-align: center;
  color: #fff;
  color: $brand-primary;
}

.videos {
  margin-bottom: 40px;
  min-height: 200px;
  min-width: 370px;
}

.slick-prev,
.slick-next {
  height: auto !important;
  width: auto !important;
  &:before {
    font-size: 38px !important;
    color: $brand-primary !important;
  }
}

.slick-prev {
  left: -45px !important;
}

.slick-next {
  right: -45px !important;
}

#slick-slide-control00:before {
  content: "♣";
  font-size: 30px;
  color: $brand-primary;
}

#slick-slide-control01:before {
  content: "♦";
  font-size: 30px;
  color: $brand-primary;
}

#slick-slide-control02:before {
  content: "♥";
  font-size: 30px;
  color: $brand-primary;
}

#slick-slide-control03:before {
  content: "♠";
  font-size: 30px;
  color: $brand-primary;
}

.slick-dots li {
  height: 35px !important;
}
