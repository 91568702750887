.container-fluid {
  overflow-x: hidden;
}

.description-wrapper {
  overflow-x: hidden;
  height: auto;
  min-height: 500px;
}

.about-title {
  margin-top: 50px;
  text-align: center;
  color: $brand-primary2;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  & h2 {
    margin-bottom: 30px;
  }
}

.sparks-content {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  & ul {
    list-style: none;
  }
  & span {
    margin-right: 24px;
  }
}

.magic-container {
  display: flex;
  justify-content: center;
}

.magic-image {
  width: 200px;
  height: 200px;
  background-size: cover;
}

.button-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.jastrow {
  height: 238px;
  margin: 30px 0px;
}

.bio {
  & h2 {
    color: $brand-primary2;
  }
}

p {
  color: $brand-primary2;
}
