// Define variables for your color scheme

// For example:
$red: #EE5F5B;
$blue: #469AE0;
$yellow: #FDB631;
$orange: #E67E22;
$green: #32B796;
$gray: #000000;
$light-gray: #F4F4F4;
