// This is where you override default Bootstrap variables
// 1. All Bootstrap variables are here => https://github.com/twbs/bootstrap-sass/blob/master/assets/stylesheets/bootstrap/_variables.scss
// 2. These variables are defined with default value (see https://robots.thoughtbot.com/sass-default)
// 3. You can override them below!

// General style
$font-family-sans-serif:  $body-font;
$headings-font-family:    $headers-font;
$body-bg:                 $light-gray;
$font-size-base: 18px;

// Colors
$gray-base:       $gray;
$brand-primary:   #A3C1AD;
$brand-primary2:  #012147;
$brand-success:   $green;
$brand-info:      $yellow;
$brand-danger:    $red;
$brand-warning:   $orange;

// Buttons & inputs' radius
$border-radius-base:  2px;
$border-radius-large: 2px;
$border-radius-small: 2px;
$font-size-base: 0.875rem;


// Patch to make simple_form compatible with bootstrap 3
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: $red;
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: $red;
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip,
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

// Override other variables below!
