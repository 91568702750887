.masterclass-detail {
  display: flex;
  justify-content: space-between;
  text-align: center;
  flex-direction: column;
  & ol {
    width: 330px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }
}


