/* -------------------------------------
 * Your CSS code for flash notices and alerts
* ------------------------------------- */

.alert {
  text-align: center;
  color: white;
  position: fixed;
  top: 0px;
  z-index: 10;
  left: 34%;
}
.alert-info {
  background: $green;
  border: none;
}
.alert-warning {
  background: $red;
  border: none;
}
