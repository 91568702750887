.testimonial-wrapper {
  height: auto;
  background-image: linear-gradient(to right, $brand-primary2, #033065);
}

.testimonial-title {
  margin-top: 50px;
  margin-bottom: 40px;
  text-align: center;
  color: $brand-primary;
}

.content-test {
  color: #fff;
}

.quotes {
  color: #fff;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.author {
  margin-top: 30px;
  float: right;
  margin-bottom: 40px;
  & p {
   color: #fff;
 }
}

.testimonials {
  display: block;
  margin: 30px auto;
  margin-bottom: 40px;
}

.slick-prev,
.slick-next {
  // left: 0px !important;
  // right: 0px !important;
  &:before {
    font-size: 38px !important;
    color: $brand-primary !important;
  }
}

#slick-slide-control10:before {
  content: "♣";
  font-size: 30px;
  color: $brand-primary;
}

#slick-slide-control11:before {
  content: "♥";
  font-size: 30px;
  color: $brand-primary;
}

#slick-slide-control12:before {
  content: "♠";
  font-size: 30px;
  color: $brand-primary;
}

#slick-slide-control13:before {
  content: "♦";
  font-size: 30px;
  color: $brand-primary;
}

.slick-dots li {
  height: 35px !important;
}
