.contact-wrapper {
  overflow-x: hidden;
  background-image: linear-gradient(to right, $brand-primary2, #033065);
}

.contact-form {
  margin-bottom: 50px;
}

.form-control.text {
  height: 150px;
}

.form-date {
  color: $brand-primary;
}

.d-flex.flex-row.justify-content-between.align-items-center {
  display: inline-flex;
}

.send-button {
  width: 100%;
  background-color: transparent;
  border: 1px solid $brand-primary;
  color: $brand-primary;
  transition: background-color ease 0.5s;
  margin-bottom: 20px;
  &:hover {
    background-color: $brand-primary;
    color: $brand-primary2;
  }
}
