.blog {
  width: 65%;
  float: right;
}

.blogs-wrapper {
  overflow-x: hidden;
  height: 100%;
  // background-image: linear-gradient(to right, $brand-primary2, #033065);
  padding-top: 120px;
}

.blog-wrapper {
  display: flex;
  justify-content: center;
}

.blogs-main {
  width: 80%
}

.blogs-title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  color: $brand-primary;
}

.blog-title {
  & a h3 {
    font-size: 28px;
    margin-top: 0px;
  }
}

.blog-detail {
  & p {
    color: #fff;
  }
}

.blog-description {
  & p {
    color: #fff;
  }
}

.blog-card {
  margin-bottom: 10px
}

.blog-image {
  background-position: 50% 50%;
  background-size: cover;
  height: 200px;
}

.blog-content {
  margin-top: 30px;
  & p {
    color: #fff;
  }
}

.blog-row {
  margin-top: 40px;
}

.read-link {
  border: 1px solid;
  padding: 3px 17px;
  border-radius: 3px;
  text-decoration: none;
  transition: background-color 1s ease;
  &:hover {
    text-decoration: none;
    background-color: $brand-primary;
    color: $brand-primary2;
  }
}

.blogs-wrapper iframe {
  position: fixed !important;
  width: 400px !important;
  height: 100vh !important;
}
