.banner {
  color: white;
  background-color: #111;
  height: 100vh;
  /* if you have a 70px navbar => height: calc(100vh - 70px); */
  background-size: 150%;
  display: flex;
  justify-content: center;
  background-position: 20% 40%;
  input{
    color: black;
  }
}
.banner2 {
  background-size: cover;
  background-position: 50% 50%;
}

.banner h1 {
  font-size: 50px;
  font-weight: bold;
  text-shadow: 0px 1px rgba(0, 0, 0, 0.2);
}
.banner p {
  font-size: 18px;
  font-weight: lighter;
  color: rgb(255, 255, 255);
  opacity: 0.8;
  margin-bottom: 30px;
}

.banner-content {
  color: #fff;
  position: absolute;
  left: 50%;
  z-index: 15;
  width: 50%;
  margin-left: -30%;
  top: 128px;
  top: 22%;
  & h4 {
    color: $brand-primary;
  }
}


.flex-div {
  display: flex;
  align-items: center;
}

.online {
  margin-top: 16px;
  font-size: 30px;
  font-weight: bold;
  color: #e25656;
  font-family: 'Special Elite', cursive;
  border: 1px #e25656;
  border-radius: 2px;
  padding: 6px 15px;
  padding-top: 12px;
  border-style: double;
  margin-left: 20px;
  width: 215px;
  padding-left: 21px;
  padding-right: 0px;
  transform: rotate(-15deg);
  border-style: dashed;
}

.book-btn {
  padding: 9px 20px;
  width: 178.05px;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 4px;
  transition: background-color ease 0.5s;
  margin-top: 16px;
  animation: glow 3s infinite;
  &:hover {
    background-color: rgb(163, 193, 173);
    color: #012146;
    border: 1px solid #111;
    content: "Too late!"
  }
  &:focus {
    outline-width: 0px;
  }
}

.book-btn-green {
  color: #fff;
  min-width: 171px;
  margin-right: 20px;
  padding: 9px 20px;
  width: 178.05px;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 4px;
  transition: background-color ease 0.5s;
  margin-top: 16px;
  animation: glow-green 3s infinite;
  &:hover {
    background-color: rgb(163, 193, 173);
    color: #012146;
    border: 1px solid #111;
    content: "Too late!"
  }
  &:focus {
    outline-width: 0px;
  }
}

@keyframes glow {
  0% { box-shadow: 0px 0px 9px #111 }
  50% { box-shadow: 0px 0px 40px red }
  100% { box-shadow: 0px 0px 9px #111 }
}

@keyframes glow-green {
  0% { box-shadow: 0px 0px 9px #111 }
  50% { box-shadow: 0px 0px 40px #2be228 }
  100% { box-shadow: 0px 0px 9px #111 }
}

.arrow-container {
  a {
    margin: 0;
    padding: 0;
    width: 50px;
  }
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
}

.down-arrow {
  font-size: 40px;
  width: 57px;
  bottom: 5vh;
  text-align: center;
  position: absolute;
  border-radius: 50%;
  animation: bounce 1s linear infinite;
}

@keyframes bounce {
  0% { transform: translateY(0px) }
  25% { transform: translateY(5px) }
  50% { transform: translateY(0px) }
  75% { transform: translateY(-5px) }
  100% { transform: translateY(0px) }

}

.main-logo {
  height: 25vmin;
}

.filter-searchbar2 {
  border-radius: 5px;
}


.up-arrow {
  text-align: center;
  font-size: 40px;
  width: 57px;
  color: #fff;
  background-color: $brand-primary;
  border-radius: 50%;
  animation: bounce 1s linear infinite;
}

.call-button {
  color: #fff;
  font-size: 15px;
  border: 1px solid $brand-primary;
  background-color: $brand-primary;
  padding: 5px;
  border-radius: 4px;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    border: 1px solid #d6d540;
    background-color: #d6d540;
    color: #f5efc1;
  }
  &:visited {
    text-decoration: none;
    color: #f5efc1;
  }
}

.carousel-fade .carousel-inner .item {
  opacity: 0;
  transition-property: opacity;
}

.carousel-fade .carousel-inner .active {
  opacity: 1;
}

.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}

.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}

.carousel-fade .carousel-control {
  z-index: 2;
}

/*
WHAT IS NEW IN 3.3: "Added transforms to improve carousel performance in modern browsers."
now override the 3.3 new styles for modern browsers & apply opacity
*/
@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-fade .carousel-inner > .item.next,
  .carousel-fade .carousel-inner > .item.active.right {
    transition: opacity 4s;
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.prev,
  .carousel-fade .carousel-inner > .item.active.left {
    transition: opacity 4s;
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.next.left,
  .carousel-fade .carousel-inner > .item.prev.right,
  .carousel-fade .carousel-inner > .item.active {
    transition: opacity 4s;
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.head-test {
  margin-top: 35px;
  width: 70%;
  & p {
    color: #fff;
  }
}

.small-text {
  font-size: 15px;
  color: $brand-primary;
  letter-spacing: 1px;
}
