// Import Google fonts
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,300,700|Raleway:400,100,300,700,500");
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700');

// Define fonts for body and headers
$body-font: "Open Sans", "Helvetica", "sans-serif";
$headers-font: 'Roboto Condensed', "Helvetica", "sans-serif";

// To use a font file (.woff) uncomment following lines
// @font-face {
//   font-family: "Font Name";
//   src: font-url('FontFile.eot');
//   src: font-url('FontFile.eot?#iefix') format('embedded-opentype'),
//        font-url('FontFile.woff') format('woff'),
//        font-url('FontFile.ttf') format('truetype')
// }
// $my-font: "Font Name";
