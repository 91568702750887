.talk-tabs {
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: none;
}

.talk-tab a {
  border: 1px solid $brand-primary !important;
  color: $brand-primary;
  border-radius: 0px !important;
  width: 170px;
  text-align: center;
}

.talk-tab a:focus {
  color: $brand-primary2 !important;
  border: 1px solid $brand-primary2 !important;
}

.talk-pane {

}

.talk-content {
  margin-top: 30px;
}


.talk-photo {
  width: 60%;
  height: 486px;
  background-size: cover;
  margin: 40px auto;
  background-position: 50% 29%;
}

.talk-content {
  margin-bottom: 30px;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.talk-book-btn {
  margin: 30px 0px;
  background-color: transparent;
  border: 1px solid #012146;
  color: #012146;
  padding: 13px 43px;
  font-size: 14px;
}

.fire {
  list-style: none;
  text-align: left;
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  & li {
    margin-bottom: 10px;
  }
  & strong {
    color: red;
  }
}
